function FileSearchIcon({ ...props }) {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.66659 14.6666H11.9999C12.3535 14.6666 12.6927 14.5262 12.9427 14.2761C13.1928 14.0261 13.3333 13.6869 13.3333 13.3333V4.99998L9.66659 1.33331H3.99992C3.6463 1.33331 3.30716 1.47379 3.05711 1.72384C2.80706 1.97389 2.66659 2.31302 2.66659 2.66665V4.66665M9.33325 1.33331V5.33331H13.3333M5.99992 12L4.99992 11M3.33325 11.3333C3.5959 11.3333 3.85597 11.2816 4.09862 11.1811C4.34127 11.0806 4.56175 10.9332 4.74747 10.7475C4.93318 10.5618 5.0805 10.3413 5.18101 10.0987C5.28152 9.85603 5.33325 9.59596 5.33325 9.33331C5.33325 9.07067 5.28152 8.8106 5.18101 8.56795C5.0805 8.32529 4.93318 8.10482 4.74747 7.9191C4.56175 7.73338 4.34127 7.58606 4.09862 7.48555C3.85597 7.38504 3.5959 7.33331 3.33325 7.33331C2.80282 7.33331 2.29411 7.54403 1.91904 7.9191C1.54397 8.29417 1.33325 8.80288 1.33325 9.33331C1.33325 9.86375 1.54397 10.3725 1.91904 10.7475C2.29411 11.1226 2.80282 11.3333 3.33325 11.3333Z'
        stroke='#09090B'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FileSearchIcon

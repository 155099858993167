import DropdownTableFilter from '@/components/DropdownTableFilter/DropdownTableFilter'
import takedownStore from '@/pages/Takedowns/takedownStore.js'
import { useStore } from 'zustand'
import { useState } from 'react'
import { useSetSelectedColumns } from '@/pages/Takedowns/hooks/useSetSelectedColumns.js'
import DatePickerWrapper from '@/components/common/Wrappers/DatePicker/DatePickerWrapper.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { Separator } from '@/components/common/ui/separator.jsx'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'
import FiltersCommandBox from '@/pages/Takedowns/pages/Home/components/FiltersCommandBox.jsx'
import PlusIcon from '@/assets/icons/PlusIcon.jsx'

const mediaColumns = [
  { label: 'Title', value: 'title' },
  { label: 'Source', value: 'source' },
  { label: 'Status', value: 'status' },
  { label: 'Last modified', value: 'last_modified' }
]
const accountsColumns = [
  { label: 'User', value: 'user_name' },
  { label: 'Source', value: 'source' },
  { label: 'Status', value: 'status' },
  { label: 'Last modified', value: 'last_modified' }
]

const selectedMediaColumns = ['title', 'source', 'status', 'last_modified']
const selectedAccountsColumns = [
  'user_name',
  'source',
  'platform',
  'status',
  'last_modified'
]

const TakedownFilters = () => {
  const {
    selectedColumns,
    selectedTab,
    setSelectedColumns,
    dateValue,
    setDateValue,
    statusValue,
    setStatusValue,
    platformValue,
    setPlatformValue
  } = useStore(takedownStore)
  const [availableColumns, setAvailableColumns] = useState(mediaColumns)
  useSetSelectedColumns({
    selectedTab,
    setSelectedColumns,
    selectedAccountsColumns,
    setAvailableColumns,
    accountsColumns,
    selectedMediaColumns,
    mediaColumns
  })

  const clearFilters = () => {
    dateValue && setDateValue(selectedTab, null)
    statusValue[selectedTab]?.length && setStatusValue(selectedTab, [])
    platformValue[selectedTab]?.length && setPlatformValue(selectedTab, [])
  }

  const handleSetStatusValue = (value) => {
    setStatusValue(selectedTab, value)
  }

  const handleDateChange = (value) => {
    setDateValue(selectedTab, value)
  }

  const isAnyFilterApplied =
    !!(dateValue && dateValue[selectedTab]) ||
    statusValue[selectedTab]?.length > 0 ||
    platformValue[selectedTab]?.length > 0

  return (
    <div className='flex items-center justify-between pb-2 gap-2'>
      {/* <div className='flex items-center'>
        <div>
          <InputWrapper
            className='h-10'
            placeholder='Filter sources..'
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            value={searchValue}
          />
        </div>
        <div className='flex px-2 items-center'>
          
          <div className='pr-2'>
            <FiltersCommandBox
              title={'Source'}
              options={sources.map((source) => ({
                label: source,
                value: source
              }))}
              selectedValues={sourceValue}
              setSelectedValues={setSourceValue}
              icon={<PlusCircle className='mr-2 h-4 w-4' />}
            />
          </div>
        </div>
      </div> */}
      <div className='flex gap-2 items-center'>
        <div>
          <DatePickerWrapper
            trigger={
              <ButtonWrapper variant='outline' className='border-dashed h-8'>
                <PlusIcon className='mr-2 h-4 w-4' />
                Date
                {dateValue &&
                  dateValue[selectedTab]?.to &&
                  dateValue[selectedTab]?.from && (
                    <>
                      <Separator orientation='vertical' className='mx-2 h-4' />

                      <BadgeWrapper
                        variant='secondary'
                        className='rounded-sm px-1 font-normal'
                        text={`${dateValue[selectedTab]?.from.toLocaleDateString('en-gb')} - ${dateValue[selectedTab]?.to.toLocaleDateString('en-gb')}`}
                      />
                    </>
                  )}
              </ButtonWrapper>
            }
            date={
              dateValue && dateValue[selectedTab]
                ? dateValue[selectedTab]
                : null
            }
            setDate={(value) => {
              handleDateChange(value)
            }}
            clearFilter={() => {
              setDateValue(selectedTab, null)
            }}
          />
        </div>
        <div>
          <FiltersCommandBox
            title={'Status'}
            options={[
              { label: 'Successful', value: 'completed' },
              { label: 'Processing', value: 'processed' },
              { label: 'Escalated', value: 'created' }
            ]}
            selectedValues={statusValue[selectedTab]}
            setSelectedValues={(value) => {
              handleSetStatusValue(value)
            }}
            icon={<PlusIcon />}
            selectedTab={selectedTab}
            // type={'singleSelect'}
          />
        </div>
        {selectedTab === 'accounts' && (
          <FiltersCommandBox
            title={'Platform'}
            options={[
              { label: 'Facebook', value: 'facebook' },
              { label: 'Instagram', value: 'instagram' },
              { label: 'TikTok', value: 'tiktok' },
              { label: 'Pinterest', value: 'pinterest' }
            ]}
            selectedValues={platformValue[selectedTab]}
            setSelectedValues={(value) => setPlatformValue(selectedTab, value)}
            icon={<PlusIcon className='mr-2 h-4 w-4' />}
            // type={'singleSelect'}
          />
        )}
        {isAnyFilterApplied && (
          <div>
            <ButtonWrapper
              text={'Clear filters'}
              className={'h-8'}
              onClick={clearFilters}
            />
          </div>
        )}
      </div>
      <div className='flex items-center justify-end'>
        <DropdownTableFilter
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
          availableTableItems={availableColumns}
          selectedTab={selectedTab}
          useTabs={false}
        />
      </div>
    </div>
  )
}

export default TakedownFilters

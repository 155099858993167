import { Button } from '@/components/common/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/components/common/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/common/ui/popover'
import { Separator } from '@/components/common/ui/separator'
import { cn } from '@/lib/utils'
import { CheckIcon } from 'lucide-react'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'

export default function FiltersCommandBox({
  title,
  options,
  selectedValues,
  setSelectedValues,
  table,
  icon,
  variant = 'outline',
  type = 'multiSelect'
}) {
  const handleSelectValue = (value) => {
    if (
      table &&
      selectedValues.length === 1 &&
      selectedValues?.includes(value)
    ) {
      return
    }
    const selectedIndex = selectedValues.indexOf(value)
    if (selectedIndex !== -1) {
      const updatedValues = [...selectedValues]
      updatedValues.splice(selectedIndex, 1)
      setSelectedValues(updatedValues)
    } else {
      setSelectedValues([...selectedValues, value])
    }
  }
  const handleSingleSelectOnly = (value) => {
    if (selectedValues?.includes(value)) {
      setSelectedValues([])
    } else {
      setSelectedValues([value])
    }
  }
  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={variant}
            className={`h-8 ${table ? 'border' : 'border-dashed'}`}
          >
            {icon}
            <p className='font-medium'> {title}</p>
            {selectedValues?.length > 0 && (
              <>
                <Separator orientation='vertical' className='mx-2 h-4' />
                <BadgeWrapper
                  variant='secondary'
                  className='rounded-sm px-1 font-normal lg:hidden'
                  text={selectedValues.length}
                />
                <div className='hidden space-x-1 lg:flex'>
                  {selectedValues.length > 2 ? (
                    <BadgeWrapper
                      variant='secondary'
                      className='rounded-sm px-1 font-normal'
                      text={`${selectedValues.length} selected`}
                    />
                  ) : (
                    options
                      .filter((option) =>
                        selectedValues?.includes(option.value)
                      )
                      .map((option) => (
                        <BadgeWrapper
                          variant='secondary'
                          key={option.value}
                          className='rounded-sm px-1 font-normal'
                          text={option.label}
                        />
                      ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-[200px] p-0' align='start'>
          <Command>
            <CommandInput placeholder={title} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues?.includes(option.value)
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        type === 'singleSelect'
                          ? handleSingleSelectOnly(option.value)
                          : handleSelectValue(option.value)
                      }}
                      className='cursor-pointer truncate'
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-5 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span>{option.label}</span>
                    </CommandItem>
                  )
                })}
              </CommandGroup>
              {table
                ? selectedValues.length < options.length && (
                    <>
                      <CommandSeparator />
                      <CommandGroup>
                        <CommandItem
                          onSelect={() => {
                            table
                              ? setSelectedValues(
                                  options.map((item) => item.value)
                                )
                              : setSelectedValues([])
                          }}
                          className='justify-center text-center'
                        >
                          Clear filter
                        </CommandItem>
                      </CommandGroup>
                    </>
                  )
                : selectedValues?.length > 0 && (
                    <>
                      <CommandSeparator />
                      <CommandGroup>
                        <CommandItem
                          onSelect={() => {
                            table
                              ? setSelectedValues(
                                  options.map((item) => item.value)
                                )
                              : setSelectedValues([])
                          }}
                          className='justify-center text-center'
                        >
                          Clear filters
                        </CommandItem>
                      </CommandGroup>
                    </>
                  )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

import { Calendar } from '../../ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover'
import { cn } from '@/lib/utils'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { Separator } from '@/components/common/ui/separator.jsx'
export default function DatePickerWrapper({
  date,
  setDate,
  trigger,
  className,
  clearFilter
}) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <div>{trigger}</div>
        </PopoverTrigger>
        <PopoverContent
          className='w-auto p-0'
          align='start'
          onFocusOutside={(e) => {
            e.preventDefault()
          }}
        >
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
            // max={31}
          />
          {date && (
            <div className={'p-1 border-t'}>
              <ButtonWrapper
                text={'Clear filter'}
                variant={'ghost'}
                className={'w-full h-8'}
                onClick={clearFilter}
              />
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

import { create } from 'zustand'

const mediaSearchStore = create((set) => ({
  mediaSearchData: {
    lastKey: null,
    pageKeys: {},
    currentPage: 1,
    isLastPage: false,
    data: null,
    appliedFilters: {},
    activeLayout: 'table'
  },
  setMediaSearchData: (data) =>
    set((state) => ({
      mediaSearchData: {
        ...state.mediaSearchData,
        ...data
      }
    })),
  loading: false,
  setLoading: (loading) => set({ loading }),

  searchValue: '',
  setSearchValue: (value) => set({ searchValue: value }),

  disableTakedownButton: false,
  setDisableTakedownButton: (value) => set({ disableTakedownButton: value }),

  resetMediaSearchData: () => {
    set({
      mediaSearchData: {
        lastKey: null,
        pageKeys: {},
        currentPage: 1,
        isLastPage: false,
        data: null,
        appliedFilters: {},
        activeLayout: 'table'
      }
    })
  }
}))

export default mediaSearchStore

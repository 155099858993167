import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import CompleteProfileSection from './components/PageSections/CompleteProfileSection/CompleteProfileSection'
import watchtowerStore from '../Watchtower/watchtowerStore'
import StatsNumbers from './components/Stats/StatsNumbers'
import { Separator } from '@/components/common/ui/separator'
import WelcomeSection from './components/PageSections/WelcomeSection/WelcomeSection'
import { useFetchAssets } from '@/pages/Home/hooks/useFetchAssets.js'
import {
  useFetchSearchSummary,
  useFetchTrending
} from '@/pages/Home/hooks/useFetchSearchSummary'
import TrendingSection from './components/PageSections/TrendingSection/TrendingSection'

export default function Home() {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    showCompleteProfileSection,
    setShowCompleteProfileSection,
    assets,
    loading
  } = useStore(globalStore)
  const { searchCounts, setSelectedTab } = useStore(watchtowerStore)
  useFetchAssets()
  useFetchSearchSummary()
  useFetchTrending()

  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/')
    }
  }, [location, navigate])
  return (
    <div className='flex w-full flex-col gap-4 max-w-screen-xl md:pl-18 md:pr-14 sm:px-20 px-8'>
      <WelcomeSection loading={loading} />
      {/* <NotificationSection loading={loading} /> */}
      {showCompleteProfileSection && assets && (
        <CompleteProfileSection
          showCompleteProfileSection={showCompleteProfileSection}
          setShowCompleteProfileSection={setShowCompleteProfileSection}
          imgNumber={assets?.length}
        />
      )}

      <StatsNumbers loading={loading} setSelectedTab={setSelectedTab} />

      <TrendingSection loading={loading} searchCounts={searchCounts} />

      <Separator />
    </div>
  )
}

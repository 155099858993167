import { getSingleSearch } from '@/redux/Watchtower/actions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import { useDebounce } from '@/pages/Watchtower/hooks/useDebounce.js'

export const useSetTabFromQuery = (setSelectedTab) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const tabFromQuery = searchParams?.get('selectedTab')
    const reviewRequired = searchParams?.get('reviewRequired')
    if (tabFromQuery) setSelectedTab(tabFromQuery)
    if (!reviewRequired && !tabFromQuery) setSelectedTab('all')
    if (reviewRequired === 'true') setSelectedTab('review_required')
  }, [searchParams, setSelectedTab])
}

export const useFetchSearches = (fetchSearches) => {
  const {
    selectedTab,
    watchtowerData,
    reviewRequiredData,
    setSelectedResults,
    setMarkedForDelete,
    threatLevel,
    setWatchtowerData,
    selectedFilterTab,
    selectedReviewRequiredTab,
    setReviewRequiredData,
    platform,
    search
  } = useStore(watchtowerStore)
  const debouncedSearch = useDebounce(search, 500)
  const data =
    selectedFilterTab === 'results' ? watchtowerData : reviewRequiredData
  const tab =
    selectedFilterTab === 'results' ? selectedTab : selectedReviewRequiredTab
  const setData =
    selectedFilterTab === 'results' ? setWatchtowerData : setReviewRequiredData
  useEffect(() => {
    setSelectedResults([])
    setMarkedForDelete([])
    const shouldResetPage =
      data[tab]?.currentPage !== 1 &&
      (data[tab]?.appliedFilters?.threat_level !== threatLevel ||
        data[tab]?.appliedFilters?.platform !== platform ||
        data[tab]?.appliedFilters?.search !== search[tab])
    //* Reset page to 1 and re-fetch the data.
    if (shouldResetPage) {
      setData(tab, {
        ...data[tab],
        currentPage: 1,
        lastKey: null,
        pageKeys: {},
        isLastPage: false,
        data: null
      })
      fetchSearches && fetchSearches(1, tab)
    } else if (
      !data[tab]?.data ||
      data[tab]?.appliedFilters?.threat_level !== threatLevel ||
      data[tab]?.appliedFilters?.platform !== platform ||
      data[tab]?.appliedFilters?.search[tab] !== debouncedSearch[tab]
    ) {
      fetchSearches && fetchSearches(data[tab].currentPage, tab)
    }

    setData(tab, {
      appliedFilters: {
        ...data[tab].appliedFilters,
        threat_level: threatLevel,
        platform,
        search
      }
    })
  }, [
    selectedTab,
    selectedFilterTab,
    selectedReviewRequiredTab,
    threatLevel,
    platform,
    debouncedSearch
  ])
}

export const useSetCurrentData = ({ id, setSingleSearch, type }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const encodedId = encodeURIComponent(id)
    if (type === 'username_platform') {
      dispatch(getSingleSearch({ payload: `username_platform=${encodedId}` }))
    } else dispatch(getSingleSearch({ payload: `search_results_id=${id}` }))
    return () => {
      setSingleSearch(null)
    }
  }, [dispatch, id])
}

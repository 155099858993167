import { useEffect } from 'react'
import { fetchMails, fetchSingleTakedown } from '@/redux/Takedowns/actions.js'

export const useFetchSingleTakedown = ({
  id,
  navigate,
  dispatch,
  tabFromQuery,
  setSingleTakedown,
  setTakedownError
}) => {
  useEffect(() => {
    if (!id) {
      navigate('/takedown')
    } else {
      dispatch(fetchSingleTakedown({ payload: { id, category: tabFromQuery } }))
      dispatch(fetchMails({ payload: { id, category: tabFromQuery } }))
    }
    return () => {
      setSingleTakedown(null)
      setTakedownError(false)
    }
  }, [id, dispatch, setSingleTakedown, navigate, setTakedownError])
}

import { useState } from 'react'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import DropdownTableFilter from '@/components/DropdownTableFilter/DropdownTableFilter'
import { useSetSelectedColumns } from '@/pages/Watchtower/hooks/useSetSelectedColumns.js'
import FiltersCommandBox from '@/pages/Takedowns/pages/Home/components/FiltersCommandBox.jsx'
import PlusIcon from '@/assets/icons/PlusIcon.jsx'
import SortingCommandBox from './SortingCommandBox'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import { LayoutGrid, Table } from 'lucide-react'
import { cn } from '@/lib/utils'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper.jsx'

const initialAvailableColumns = [
  { label: 'Result', value: 'result' },
  { label: 'Source', value: 'source' },
  { label: 'Threat Level', value: 'threat_level' }
]
const initSelectedColumns = ['result', 'source', 'threat_level']
const impersonationsColumns = ['followers', 'following']

export default function Filters() {
  const {
    selectedColumns,
    setSelectedColumns,
    selectedTab,
    threatLevel,
    setThreatLevel,
    platform,
    setPlatform,
    activeLayout,
    setActiveLayout,
    selectedReviewRequiredTab,
    selectedFilterTab,
    search,
    setSearch
  } = useStore(watchtowerStore)
  const [availableColumns, setAvailableColumns] = useState(
    initialAvailableColumns
  )
  useSetSelectedColumns({
    selectedTab,
    setSelectedColumns,
    initSelectedColumns,
    impersonationsColumns,
    setAvailableColumns,
    initialAvailableColumns
  })

  const handleThreatLevelChange = (value) => {
    setThreatLevel(value)
  }

  //* Check if any filter is applied: if results filter tab is selected, check if threat level filter is applied, if selected tab is impersonations also check platform filter, for review area filter tab only check for platform filter.
  const isAnyFilterApplied =
    selectedFilterTab === 'results'
      ? threatLevel.length > 0 ||
        (selectedTab === 'impersonations' &&
          (platform[selectedFilterTab].length > 0 ||
            search[selectedTab]?.length > 0))
      : platform[selectedFilterTab].length > 0 &&
        selectedReviewRequiredTab === 'impersonations'

  const onLayoutSwitch = (value) => {
    setActiveLayout(value)
  }

  const clearFilters = () => {
    if (selectedFilterTab === 'results') {
      setThreatLevel([])
      setSearch(selectedTab, '')
    }
    setPlatform(selectedFilterTab, [])
  }
  return (
    <div className='py-2 flex flex-row flex-wrap lg:flex-nowrap items-start lg:items-center gap-2 justify-between w-full'>
      <div className='flex items-center justify-start gap-2 flex-wrap lg:flex-nowrap'>
        {selectedFilterTab === 'results' &&
          selectedTab === 'impersonations' && (
            <div className={''}>
              <InputWrapper
                className={'w-full h-8'}
                value={search[selectedTab]}
                onChange={(e) => {
                  setSearch(selectedTab, e.target.value)
                }}
                placeholder={'Search with username...'}
              />
            </div>
          )}
        {((selectedFilterTab === 'results' &&
          selectedTab === 'impersonations') ||
          (selectedFilterTab === 'review_required' &&
            selectedReviewRequiredTab === 'impersonations')) && (
          <FiltersCommandBox
            title={'Platform'}
            options={[
              { label: 'Facebook', value: 'facebook' },
              { label: 'Instagram', value: 'instagram' },
              { label: 'TikTok', value: 'tiktok' },
              { label: 'Pinterest', value: 'pinterest' }
            ]}
            selectedValues={platform[selectedFilterTab]}
            setSelectedValues={(value) => setPlatform(selectedFilterTab, value)}
            icon={<PlusIcon className='mr-2 h-4 w-4' />}
            // type={'singleSelect'}
          />
        )}

        {selectedFilterTab === 'results' && (
          <FiltersCommandBox
            title={'Threat Level'}
            options={[
              { label: 'Low', value: 'low' },
              { label: 'Moderate', value: 'moderate' },
              { label: 'High', value: 'high' },
              { label: 'Critical', value: 'critical' }
            ]}
            selectedValues={threatLevel}
            setSelectedValues={(value) => handleThreatLevelChange(value)}
            icon={<PlusIcon className='mr-2 h-4 w-4' />}
            type={'singleSelect'}
          />
        )}

        {activeLayout === 'grid' && selectedFilterTab === 'results' && (
          <SortingCommandBox />
        )}

        {isAnyFilterApplied && (
          <div>
            <ButtonWrapper
              text={'Clear filters'}
              className={'h-8'}
              onClick={clearFilters}
            />
          </div>
        )}
      </div>

      <div className='flex justify-start lg:justify-end gap-2'>
        {selectedFilterTab === 'results' && (
          <>
            <div className='flex items-center justify-center whitespace-nowrap border border-input bg-background hover:text-accent-foreground shadow-sm rounded-md px-1 h-8 gap-1'>
              <TooltipWrapper
                component={
                  <div
                    onClick={() => onLayoutSwitch('grid')}
                    className={cn(
                      'flex items-center justify-center cursor-pointer h-6 w-6 rounded-md hover:bg-accent transition-colors',
                      {
                        'bg-accent text-accent-foreground border border-input shadow-sm':
                          activeLayout === 'grid'
                      }
                    )}
                  >
                    <LayoutGrid className='w-4 h-4' strokeWidth={1.5} />
                  </div>
                }
                text='Grid View'
                side='top'
                delayDuration={20}
              />

              <TooltipWrapper
                component={
                  <div
                    onClick={() => onLayoutSwitch('table')}
                    className={cn(
                      'flex items-center justify-center cursor-pointer h-6 w-6 rounded-md hover:bg-accent transition-colors',
                      {
                        'bg-accent text-accent-foreground border border-input shadow-sm':
                          activeLayout === 'table'
                      }
                    )}
                  >
                    <Table className='w-4 h-4' strokeWidth={1.5} />
                  </div>
                }
                text='Table View'
                side='top'
                delayDuration={20}
              />
            </div>

            <div className='w-full flex justify-end'>
              <DropdownTableFilter
                setSelectedColumns={setSelectedColumns}
                selectedColumns={selectedColumns}
                availableTableItems={availableColumns}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

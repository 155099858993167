import { ArrowLeft, Layers } from 'lucide-react'
import { Separator } from '@/components/common/ui/separator'
import TakedownCustomBadge from '../Home/components/TakedownCustomBadge'
import InfoSection from './components/InfoSection'
import Timeline from './components/Timeline'
import AllActivity from './components/AllActivity'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { useEffect } from 'react'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import { useDispatch } from 'react-redux'
import takedownStore from '../../takedownStore'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import _ from 'lodash'
import { getDates } from '../../utils'
import { Button } from '@/components/common/ui/button'
import { useFetchSingleTakedown } from '@/pages/Takedowns/hooks/useFetchSingleTakedown.js'
export default function TakedownActivity() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    singleTakedown,
    setSingleTakedown,
    takedownLoading,
    mails,
    takedownError,
    setTakedownError,
    setSelectedTab,
    error
  } = useStore(takedownStore)
  const { id } = useParams()
  const { loading } = useStore(globalStore)
  const [searchParams] = useSearchParams()
  const tabFromQuery = searchParams?.get('selectedTab')

  useEffect(() => {
    if (tabFromQuery) {
      setSelectedTab(tabFromQuery)
    }
  }, [tabFromQuery, setSelectedTab])

  useFetchSingleTakedown({
    id,
    navigate,
    dispatch,
    tabFromQuery,
    setSingleTakedown,
    setTakedownError
  })

  const handleBack = () => {
    if (location?.key === 'default') {
      navigate('/takedown', { replace: true })
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (error) {
      navigate('/takedown', { replace: true })
    }
  }, [error])

  const groupedMails = Array.isArray(mails?.data)
    ? _.groupBy(mails.data, 'info_type')
    : { domain: [mails?.data] }
  return (
    <div className='w-full max-w-screen-xl px-6 md:px-16'>
      {loading ? (
        <SkeletonWrapper height={'2rem'} width={'100%'} number={1} />
      ) : (
        <div className='flex justify-between items-center text-muted-foreground'>
          <div className='flex gap-4 items-center'>
            <Button
              variant='ghost'
              size='sm'
              className='gap-1 ml-[-15px] text-green-600 hover:text-green-600'
              onClick={handleBack}
            >
              <ArrowLeft className='w-4 h-4' />
              Back
            </Button>

            <div className='flex text-sm items-center'>
              <div className=''>
                <Layers width={'13px'} />
              </div>
              <div className='px-1'>
                <p>Takedown</p>
              </div>
            </div>
          </div>
          <div className={'text-right'}>#{id}</div>
        </div>
      )}
      <div className='flex py-4 items-center justify-between'>
        <div className='min-w-[70%] max-w-[90%]'>
          {takedownLoading ? (
            <SkeletonWrapper height={'2rem'} width={'100%'} number={1} />
          ) : (
            <p className='text-xl text-foreground font-semibold truncate'>
              {singleTakedown?.data?.title ||
                singleTakedown?.data?.username ||
                'Unavailable'}
            </p>
          )}
        </div>
        <div className='pl-4'>
          {takedownLoading ? (
            <SkeletonWrapper height={'2rem'} width={'6rem'} number={1} />
          ) : (
            <TakedownCustomBadge text={singleTakedown?.data?.status}>
              Successful
            </TakedownCustomBadge>
          )}
        </div>
      </div>
      <Separator />
      <div className='w-full'>
        {takedownLoading ? (
          <SkeletonWrapper
            height={'6rem'}
            width={'100%'}
            number={1}
            className={'py-2'}
          />
        ) : (
          <InfoSection
            data={{
              lastUpdated:
                singleTakedown?.data?.takedown_updatedAt ||
                singleTakedown?.data?.takedown_updated_at,
              category:
                singleTakedown?.data?.category ||
                singleTakedown?.data?.platform,
              url: singleTakedown?.data?.page_url || ''
            }}
          />
        )}
        {takedownLoading ? (
          <SkeletonWrapper
            height={'15rem'}
            width={'100%'}
            number={1}
            className={'py-2'}
          />
        ) : (
          <Timeline
            status={singleTakedown?.data?.status}
            date={getDates(singleTakedown)}
          />
        )}
        <div className='pt-4 '>
          {takedownLoading ? (
            <SkeletonWrapper
              height={'20rem'}
              width={'100%'}
              number={1}
              className={'py-2'}
            />
          ) : (
            !takedownError && (
              <AllActivity
                status={singleTakedown?.data?.status}
                takedown={singleTakedown?.data}
                mails={groupedMails}
                date={getDates(singleTakedown)}
                email_address={mails?.data?.email_address}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}

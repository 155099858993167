import { Separator } from '@/components/common/ui/separator'
import WatchtowerBadge from '../Home/components/Tabs/components/WatchtowerBadge'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'zustand'
import watchtowerStore from '../../watchtowerStore'
import { useEffect, useState } from 'react'
import GlobeIcon from '@/assets/icons/GlobeIcon'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import { AlertDialogWrapper } from '@/components/common/Wrappers/AlertDialog/AlertDialog'
import globalStore from '@/zustand/globalStore'
import { useDispatch } from 'react-redux'
import { createTakedown } from '@/redux/Takedowns/actions'
import { useSetCurrentData } from '@/pages/Watchtower/hooks/useWatchtowerHooks'
import InfoSection from './components/InfoSection'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage'
import { useSetImage } from '@/pages/Watchtower/hooks/useSingleWatchtower'
import ToastContainer from '@/components/ToastContainer/ToastContainer'
import { toast } from 'sonner'
import { getCategoryFromIndexType } from '@/lib/utils'
import { Button } from '@/components/common/ui/button'
import { ArrowLeft } from 'lucide-react'

export default function WatchtowerActivity() {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [imageSrc, setImageSrc] = useState(null)
  const {
    selectedTab,
    markForTakedownStatus,
    setMarkForTakedownStatus,
    singleSearch,
    setSingleSearch,
    loading
  } = useStore(watchtowerStore)

  const params = new URLSearchParams(location.search)
  const type = params.get('type')
  const finalId = id.includes('%23') ? decodeURIComponent(id) : id

  const { hideSensitiveContent } = useStore(globalStore)
  const dispatch = useDispatch()
  useSetCurrentData({
    id: finalId,
    setSingleSearch,
    selectedTab,
    type
  })

  useSetImage({ setImageSrc, singleSearch, type })

  const showToastNotification = (status, navigate, singleSearch, loading) => {
    if (status === true) {
      toast.success(
        <ToastContainer
          title={'Request created'}
          description={'Your takedown request has been created.'}
          onView={() => {
            navigate(`/takedown/${singleSearch?.takedown_id}`)
          }}
          loading={loading}
        />
      )
    } else if (status === false) {
      toast.error(
        <ToastContainer
          title={'An error occurred'}
          description={'Your takedown request could not be created.'}
        />
      )
    }
  }

  useEffect(() => {
    showToastNotification(
      markForTakedownStatus.success,
      navigate,
      singleSearch,
      loading
    )
    setMarkForTakedownStatus({ success: null })
  }, [setMarkForTakedownStatus])

  const handleCreateTakedown = () => {
    dispatch(createTakedown({ payload: { id } }))
  }

  const handleBack = () => {
    if (location?.key === 'default') {
      navigate('/watchtower', { replace: true })
    } else {
      navigate(-1)
    }
  }

  const category = getCategoryFromIndexType(singleSearch?.index_type)
  return (
    <div className='w-full max-w-screen-xl px-6 md:px-16'>
      {loading ? (
        <SkeletonWrapper width='100%' height='32px' number={1} />
      ) : (
        <div className='flex flex-col md:flex-row items-start md:items-center justify-between text-muted-foreground'>
          <div className='flex items-center gap-4'>
            <Button
              variant='ghost'
              size='sm'
              className='gap-1 ml-[-15px] text-green-600 hover:text-green-600'
              onClick={handleBack}
            >
              <ArrowLeft className='w-4 h-4' />
              Back
            </Button>
            <div className='flex items-center'>
              <GlobeIcon />
              <div className='px-1'>
                <p>Result</p>
              </div>
            </div>
          </div>
          <span className='text-sm truncate w-full md:w-auto'>#{id}</span>
        </div>
      )}
      {loading ? (
        <SkeletonWrapper width={'100%'} height={'64px'} number={1} />
      ) : (
        <div className='flex py-4 items-center justify-between gap-4'>
          <div className='flex items-center truncate'>
            <div className='w-full'>
              <p className='text-xl text-foreground font-semibold truncate max-w-[800px]'>
                {singleSearch?.results?.title ||
                  singleSearch?.title ||
                  'Unavailable'}
              </p>
            </div>
            {singleSearch?.results?.review_required && (
              <div className='px-3  w-full'>
                <WatchtowerBadge
                  text={
                    singleSearch?.results?.review_required ||
                    singleSearch?.review_required
                      ? 'yes'
                      : 'no'
                  }
                  watchtowerInfo={true}
                />
              </div>
            )}
          </div>
          <div className=''>
            {singleSearch?.takedown_id ? (
              <ButtonWrapper
                text='View'
                onClick={() => {
                  navigate(
                    selectedTab === 'impersonations'
                      ? `/takedown/${singleSearch?.takedown_id}?selectedTab=accounts`
                      : `/takedown/${singleSearch?.takedown_id}?selectedTab=media`
                  )
                }}
              />
            ) : (
              type !== 'username_platform' && (
                <AlertDialogWrapper
                  trigger={
                    <ButtonWrapper
                      text='Mark for takedown'
                      disabled={category !== 'adult'}
                    />
                  }
                  alertTitle={'Mark for takedown'}
                  alertDescription={
                    "Marking this for takedown will kick-off Loti's takedown service. Please be certain that this content is unwanted before proceeding."
                  }
                  alertOk={'Continue'}
                  alertCancel={'Cancel'}
                  handleOk={handleCreateTakedown}
                />
              )
            )}
          </div>
        </div>
      )}
      <Separator />
      <div className='w-full pt-4'>
        {loading ? (
          <SkeletonWrapper width={'100%'} height={'160px'} number={1} />
        ) : (
          <InfoSection
            domain={singleSearch?.page_url || singleSearch?.results?.page_url}
            category={category || singleSearch?.results?.category || '--'}
            page_url={singleSearch?.results?.page_url || singleSearch?.page_url}
            followers={
              singleSearch?.results?.followers || singleSearch?.followers
            }
            selectedTab={selectedTab}
            type={type}
            isDeepfake={singleSearch?.is_deepfake}
          />
        )}
        {loading ? (
          <SkeletonWrapper width={'100%'} height={'320px'} number={1} />
        ) : (
          <>
            <div className='pt-5 pb-4'>
              <div className='font-medium text-[1rem] pb-4'>
                <p>Preview image</p>
              </div>
              <Separator />
            </div>
            <div className=''>
              <NsfwImageSection
                img={imageSrc || singleSearch?.face_url}
                intermediateFallbackImg={singleSearch?.image_url}
                imgFallback={singleSearch?.frame_url}
                hideSensitiveContent={hideSensitiveContent}
                category={category}
                canPreviewFullImage={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

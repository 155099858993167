import { getColor } from '@/lib/utils'
import { useState } from 'react'
import {
  BarChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  Bar,
  Tooltip
} from 'recharts'
import colors from 'tailwindcss/colors'
import globalStore from '@/zustand/globalStore.js'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import { useStore } from 'zustand'

const CustomYAxisTick = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-95} y={0} dy={6} textAnchor='start' fill='#666'>
        {payload.value}
      </text>
    </g>
  )
}

const customTooltip = ({ payload = [], active = false }) => {
  if (active) {
    return (
      <div className='w-full h-full border px-3 py-1 bg-background rounded-md text-sm text-foreground'>
        <p className='label'>{payload[0]?.payload?.value?.toLocaleString()}</p>
      </div>
    )
  }

  return null
}

// Normalize the value using log scale
const normalizeValue = (value) => {
  // A small minimum value to avoid log(0)
  const minValue = 1
  return Math.log10(value + minValue)
}

const CategoriesChart = ({ searchCounts }) => {
  const [barHovered, setBarHovered] = useState(false)
  const { loading } = useStore(globalStore)

  const data = searchCounts
    .filter((item) => item.label !== 'All' && item.value !== 0)
    .sort((a, b) => b.value - a.value)
    .map((item) => ({
      ...item,
      normalizeValue: normalizeValue(item.value)
    }))

  const categoryColors = [
    colors.blue[500],
    colors.red[500],
    colors.pink[500],
    getColor('--bg-primary'),
    colors.gray[400]
  ]

  const maxBarHeight = 50
  const chartHeight = Math.min(data.length * maxBarHeight, 210)
  return (
    <div className={'flex flex-col gap-3 w-full h-full border rounded-md p-4'}>
      <h2 className={'text-sm text-text-muted'}>Top categories</h2>
      {loading ? (
        <SkeletonWrapper height={'15rem'} width={'100%'} number={1} />
      ) : (
        <div className='flex flex-col justify-between h-full'>
          <ResponsiveContainer width='100%' height={chartHeight}>
            <BarChart data={data} layout='vertical' barCategoryGap={3}>
              <XAxis type='number' hide />
              <YAxis
                type='category'
                dataKey='label'
                width={100}
                axisLine={false}
                tick={<CustomYAxisTick />}
                tickLine={false}
                className='text-xs text-muted-foreground'
                padding={2}
              />
              <Tooltip
                content={customTooltip}
                cursor={{ fill: 'transparent' }}
                active={barHovered}
              />
              <Bar
                dataKey='normalizeValue'
                radius={5}
                minPointSize={5}
                onMouseEnter={() => setBarHovered(true)}
                onMouseLeave={() => setBarHovered(false)}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={categoryColors[index]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <div className='pt-4'>
            <p className='text-sm font-normal text-muted-foreground'>
              The top categories your likeness appears online
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default CategoriesChart

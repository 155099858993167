import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { SearchIcon, X } from 'lucide-react'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage.jsx'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getSearchMedia } from '@/redux/MediaSearch/actions.js'
import ActionDock from '@/components/ActionDock/ActionDock.jsx'
import { useStore } from 'zustand'
import mediaSearchStore from '@/pages/MediaSearch/mediaSearchStore.js'
import Pagination from '@/components/Pagination/Pagination.jsx'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import { cn } from '@/lib/utils.js'
import { createTakedown } from '@/redux/Takedowns/actions.js'
import globalStore from '@/zustand/globalStore.js'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckResultType } from '@/pages/MediaSearch/hooks/useCheckResultsType.js'
import FileSearchIcon from '@/assets/icons/FileSearchIcon.jsx'
import BreadcrumbWrapper from '@/components/common/Wrappers/Breadcrumb/Breadcrumb.jsx'

const suggestions = [
  'Blood',
  'Drugs',
  'Smoking',
  'Nudity',
  'Violence',
  'Guns'
].sort((a, b) => a.localeCompare(b))

function MediaSearch() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState([])
  const {
    mediaSearchData,
    loading,
    setMediaSearchData,
    searchValue,
    setSearchValue,
    disableTakedownButton,
    setDisableTakedownButton,
    resetMediaSearchData
  } = useStore(mediaSearchStore)
  const { hideSensitiveContent } = useStore(globalStore)
  const [searchQuery, setSearchQuery] = useState('')
  const { keyword } = useParams()
  const handleSearch = ({ lastKey, key }) => {
    if ((searchValue || key) && !loading) {
      setSelectedItems([])
      setSearchQuery(key ?? searchValue)
      navigate(`/search-media/${key ?? searchValue}`)
      dispatch(
        getSearchMedia({
          payload: { search: key ?? searchValue, ...(lastKey && { lastKey }) }
        })
      )
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchValue) handleSearch({ lastKey: null })
    }
  }

  const handlePageChange = (page) => {
    setMediaSearchData({ currentPage: page })
    handleSearch({ lastKey: mediaSearchData?.pageKeys[page] || '' })
    window?.scrollTo(0, 0)
  }

  const handleSuggestingClick = (key) => {
    if (searchValue === key) return
    setSearchValue(key)
    handleSearch({ lastKey: null, key })
  }

  const handleCheckboxChange = (itemId, isChecked) => {
    if (isChecked) {
      setSelectedItems((prev) => [...prev, itemId])
    } else {
      const excluded = selectedItems.filter((item) => item !== itemId)
      setSelectedItems(excluded)
    }
  }

  useCheckResultType({
    setDisableTakedownButton,
    selectedItems,
    data: mediaSearchData?.data
  })

  const handlePrimary = () => {
    const searchResultsIds = selectedItems.map(encodeURIComponent).join(',')
    let payload = {
      id: searchResultsIds,
      tab: 'mediaSearch',
      key: 'array'
    }

    if (selectedItems.length > 1) {
      payload = {
        ...payload,
        key: 'array'
      }
    }
    dispatch(createTakedown({ payload }))
    setSelectedItems([])
  }

  useEffect(() => {
    if (keyword) {
      setSearchValue(keyword)
      handleSearch({ lastKey: null, key: keyword })
    }
  }, [keyword])

  const handleDelete = () => {
    setSearchValue('')
    setSelectedItems([])
    resetMediaSearchData()
    setSearchQuery('')
    navigate('/search-media')
  }

  return (
    <div className='w-full max-w-screen-xl md:px-16 px-6 pb-8 relative'>
      <div className={'bg-green-50 -mx-[18rem] -my-8'}>
        <div className={'mx-[17rem] pt-8'}>
          <div className={'text-sm mb-4 flex items-center gap-2'}>
            <FileSearchIcon />
            <BreadcrumbWrapper
              pages={[{ name: 'Watchtower', url: '/watchtower' }]}
              currentPage={{
                name: 'Media Search',
                url: '/media-search'
              }}
            />
          </div>
          <div className='flex justify-between items-center'>
            <p className='text-xl font-semibold text-foreground text-green-600'>
              Search Media
            </p>
          </div>
          <div className='w-full relative mt-3'>
            <div className='relative shadow-sm'>
              <InputWrapper
                className='w-full h-[3rem] flex items-center px-12 rounded-lg font-medium'
                placeholder={'Keyword or description...'}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className='top-3 left-2 px-1 absolute'>
              <SearchIcon className={'w-5 h-5 mt-[2px]'} />
            </div>
            <div className='top-1 right-1 absolute'>
              <ButtonWrapper text='Search' onClick={handleSearch} />
            </div>
          </div>
          <div className={'mt-2 mb-4 pb-4 w-full'}>
            <div className='w-full flex gap-4 items-center'>
              <div className={'text-muted-foreground text-sm font-medium'}>
                <p>Suggestions:</p>
              </div>
              <div className={'flex items-center gap-1'}>
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={cn(
                      'px-3 rounded-lg py-1 text-sm flex items-center gap-1 font-medium cursor-pointer',
                      {
                        'bg-green-600 text-background ':
                          searchValue === suggestion
                      }
                    )}
                  >
                    <div
                      onClick={() => {
                        handleSuggestingClick(suggestion)
                      }}
                    >
                      {suggestion}
                    </div>
                    {searchValue === suggestion && (
                      <div className={'cursor-pointer'} onClick={handleDelete}>
                        <X className={'w-4 h-4'} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'mt-16'}>
        {loading ? (
          <SkeletonWrapper number={1} height={'3rem'} width={'100%'} />
        ) : (
          searchQuery && (
            <div className={'mt-16'}>
              <div className={'text-muted-foreground flex items-center gap-1'}>
                <p>Results found for </p>
                <div className={'flex items-center'}>
                  <p>&quot;</p>
                  <p className={'text-green-600 underline font-semibold'}>
                    {searchQuery}
                  </p>
                  <p>&quot;.</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className={'my-6 grid grid-cols-4 w-full items-center gap-4'}>
        {loading
          ? Array.from({ length: 8 }).map((_, index) => (
              <SkeletonWrapper
                key={index}
                number={1}
                height={'20rem'}
                width={'100%'}
              />
            ))
          : mediaSearchData?.data?.map((item, index) => (
              <div key={index} className={'w-full h-[20rem]'}>
                <NsfwImageSection
                  checkbox={true}
                  title={item?.title}
                  img={item.frame_url}
                  nsfw={false}
                  imgFallback={item.face_url}
                  intermediateFallbackImg={item.image_url}
                  showMetaData={true}
                  category={item?.category}
                  checkboxData={{
                    id: item?.search_results_id,
                    checked: selectedItems?.includes(item?.search_results_id),
                    onCheckedChange: (e) => {
                      handleCheckboxChange(item?.search_results_id, e)
                    }
                  }}
                  className={'w-full h-full'}
                  hideSensitiveContent={hideSensitiveContent}
                  handleNavigation={() => {
                    navigate(`/watchtower/${item.search_results_id}`)
                  }}
                  imgPointer={true}
                  link={item.page_url}
                />
              </div>
            ))}
      </div>

      <div className='fixed bottom-0 z-50 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <div className={`flex justify-center transition-all duration-300 mb-2`}>
          <div
            className={`flex justify-center transition-all duration-300 mb-2 ${
              selectedItems?.length > 0
                ? 'opacity-100 max-h-20'
                : 'opacity-0 max-h-0'
            }`}
          >
            <ActionDock
              dataLength={selectedItems?.length}
              primaryAction={{
                text: 'Mark for takedown',
                alertTitle: 'Mark for takedown',
                alertDescription:
                  "Marking this for takedown will kick-off Loti's takedown service. Please be certain that this content is unwanted before proceeding.",
                alertOk: 'Continue',
                alertCancel: 'Cancel',
                handlePrimary,
                disabled: disableTakedownButton
              }}
            />
          </div>
        </div>
        {mediaSearchData?.data?.length > 0 && (
          <Pagination
            currentPage={mediaSearchData?.currentPage}
            handlePageChange={handlePageChange}
            disableNext={mediaSearchData?.isLastPage || loading}
            disablePrev={mediaSearchData?.currentPage === 1 || loading}
            pageKeys={Object.keys(mediaSearchData?.pageKeys)}
            loading={mediaSearchData?.loading}
          />
        )}
      </div>
    </div>
  )
}

export default MediaSearch

import { combineReducers } from 'redux'
import authReducer from './Auth/reducer'
import userReducer from './User/reducer'
import takedownsReducer from './Takedowns/reducer'
import assetsReducer from './Assets/reducer'
import dashboardReducer from './Dashboard/reducer'
import watchtowerReducer from './Watchtower/reducer'
import mediaSearchReducer from './MediaSearch/reducer.js'
const rootReducer = combineReducers({
  authReducer,
  userReducer,
  takedownsReducer,
  assetsReducer,
  dashboardReducer,
  watchtowerReducer,
  mediaSearchReducer
})
export default rootReducer

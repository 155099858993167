import * as actionTypes from './actionTypes'
import mediaSearchStore from '@/pages/MediaSearch/mediaSearchStore.js'
const initialState = {
  error: false,
  loading: false,
  errorMessage: ''
}

const mediaSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEARCH_MEDIA:
      mediaSearchStore.getState().setLoading(true)
      return {
        ...state,
        loading: true
      }
    case actionTypes.GET_SEARCH_MEDIA_SUCCESS: {
      mediaSearchStore.getState().setLoading(false)
      const { results, lastKey } = action.payload
      const previousData = mediaSearchStore.getState().mediaSearchData
      const currentPage = previousData.currentPage
      mediaSearchStore.getState().setMediaSearchData({
        data: results,
        lastKey,
        isLastPage: !lastKey,
        pageKeys: {
          ...previousData.pageKeys,
          [currentPage + 1]: lastKey || ''
        }
      })
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.GET_SEARCH_MEDIA_FAILURE:
      mediaSearchStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    default:
      return state
  }
}

export default mediaSearchReducer

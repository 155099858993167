import { Checkbox } from '@/components/common/ui/checkbox.jsx'

function CheckboxWrapper({
  id,
  className,
  onCheckedChange,
  checked,
  defaultChecked
}) {
  return (
    <Checkbox
      id={id}
      className={className}
      onCheckedChange={onCheckedChange}
      checked={checked}
      defaultChecked={defaultChecked}
    />
  )
}

export default CheckboxWrapper

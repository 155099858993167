import AdjustIcon from '@/assets/icons/AdjustIcon'
import { Button } from '@/components/common/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/common/ui/dropdown-menu'

export default function DropdownTableFilter({
  selectedColumns,
  setSelectedColumns,
  availableTableItems
}) {
  const handleCheckboxChange = (item, event) => {
    event.preventDefault()

    const currentColumns = selectedColumns || []
    const updatedColumns = currentColumns?.includes(item)
      ? currentColumns.length > 1
        ? currentColumns.filter((i) => i !== item)
        : currentColumns // Prevent deselecting the last column
      : [...currentColumns, item]
    setSelectedColumns(updatedColumns)
  }

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant='outline' size='sm' className='ml-auto h-8 lg:flex'>
            <AdjustIcon />
            View
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end' className='w-[170px]'>
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {availableTableItems.map((item, index) => (
            <DropdownMenuCheckboxItem
              key={index}
              className='capitalize'
              checked={selectedColumns?.includes(item.value)}
              onClick={(event) => handleCheckboxChange(item.value, event)}
            >
              {item.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

import { Badge } from '@/components/common/ui/badge'
import SocialIcon from '@/components/common/ui/social-icon'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'

export default function InfoSection({
  domain,
  category,
  page_url,
  selectedTab,
  followers,
  type,
  isDeepfake
}) {
  return (
    <>
      <div className='w-full flex flex-col lg:flex-row gap-4 lg:gap-10 py-4 flex-wrap overflow-hidden'>
        <div className='w-full sm:w-auto'>
          <div className='text-sm text-muted-foreground'>
            <p>Source</p>
          </div>
          <div className='text-sm font-medium text-green-600'>
            <a
              className='flex gap-2 items-center '
              href={page_url}
              target='_blank'
            >
              <div className='flex items-center'>
                {category ? (
                  <TooltipWrapper
                    component={
                      <SocialIcon className='w-4 h-4' platform={category} />
                    }
                    text={
                      category === 'adult'
                        ? 'Sensitive Content'
                        : category.charAt(0).toUpperCase() + category.slice(1)
                    }
                    delayDuration={20}
                  />
                ) : null}
              </div>
              <span className='w-10/12 md:w-96 truncate'>{domain || '--'}</span>
            </a>
          </div>
        </div>
        {selectedTab === 'impersonations' ||
          (type === 'username_platform' && (
            <div className=''>
              <div className='text-sm text-muted-foreground'>
                <p>Followers</p>
              </div>
              <div className='py-1 text-sm text-foreground'>
                <p>{followers?.toLocaleString('en-us') || '--'}</p>
              </div>
            </div>
          ))}

        {isDeepfake ? (
          <div className=''>
            <div className='text-sm text-muted-foreground'>
              <p>Tags</p>
            </div>
            <Badge variant='breached'>Deepfake</Badge>
          </div>
        ) : null}
      </div>
    </>
  )
}

import { useEffect } from 'react'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore.js'

export const useFetchData = ({ takedownData, selectedTab, fetchData }) => {
  const { dateValue, statusValue, setTakedownData, platformValue } =
    useStore(takedownStore)

  const currentDateValue =
    dateValue && selectedTab ? dateValue[selectedTab] : null
  const currentStatusValue = statusValue[selectedTab]
  const currentPlatformValue = platformValue[selectedTab]
  const page = takedownData[selectedTab]?.currentPage
  useEffect(() => {
    //* Check if data for the selected tab is not available

    const shouldResetPage =
      takedownData[selectedTab]?.currentPage !== 1 &&
      (takedownData[selectedTab]?.appliedFilters?.date !== currentDateValue ||
        takedownData[selectedTab]?.appliedFilters?.platform !==
          currentPlatformValue ||
        takedownData[selectedTab]?.appliedFilters?.status !==
          currentStatusValue)

    if (shouldResetPage) {
      setTakedownData(selectedTab, {
        ...takedownData[selectedTab],
        currentPage: 1,
        lastKey: null,
        pageKeys: {},
        isLastPage: false,
        data: null
      })
      fetchData(page)
    } else if (
      !takedownData[selectedTab]?.data ||
      //* Check if the date filter has changed and a valid date range is selected
      (takedownData[selectedTab]?.appliedFilters.date !== currentDateValue &&
        takedownData[selectedTab].data &&
        currentDateValue?.to &&
        currentDateValue?.from) ||
      //* Check if the status filter has changed
      (takedownData[selectedTab]?.appliedFilters.status !==
        currentStatusValue &&
        takedownData[selectedTab].data) ||
      //* Check if the platform filter has changed
      (takedownData[selectedTab]?.appliedFilters.platform !==
        currentPlatformValue &&
        takedownData[selectedTab].data)
    ) {
      //* Fetch data for the current page
      fetchData(page)
    }
    //* Update the applied filters in the takedown data for the current tab
    setTakedownData(selectedTab, {
      appliedFilters: {
        ...takedownData[selectedTab].appliedFilters,
        date: currentDateValue,
        status: currentStatusValue,
        platform: currentPlatformValue
      }
    })
  }, [
    selectedTab,
    currentDateValue,
    page,
    currentStatusValue,
    currentPlatformValue
  ])
}

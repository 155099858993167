import { cn } from '@/lib/utils'
import { X, ZoomInIcon } from 'lucide-react'
import { useState } from 'react'

export default function FullImagePreview({ src }) {
  const [isFullPreviewOpen, setIsFullPreviewOpen] = useState(false)

  const onImageFullToggle = () => {
    setIsFullPreviewOpen(!isFullPreviewOpen)
  }

  return (
    <>
      <div
        onClick={onImageFullToggle}
        className='absolute top-2 right-2 bg-white bg-opacity-60 rounded-full h-6 w-6 items-center justify-center cursor-pointer z-10 hover:bg-accent shadow-sm hidden group-hover:flex'
      >
        <ZoomInIcon className='w-4 h-4' />
      </div>

      <div
        className={cn(
          'fixed w-dvh h-dvh inset-0 p-10 bg-black/75 overflow-auto items-center justify-center z-50 transition-all duration-300 ease-in-out',
          {
            'flex opacity-100 scale-100': isFullPreviewOpen,
            'flex opacity-0 scale-95 pointer-events-none': !isFullPreviewOpen
          }
        )}
        onClick={onImageFullToggle}
      >
        <div
          className='bg-background shadow-sm rounded-full w-6 h-6 fixed top-10 right-10 flex items-center justify-center cursor-pointer hover:bg-accent z-50'
          onClick={onImageFullToggle}
        >
          <X className='w-4 h-4' />
        </div>

        <img
          src={src}
          className={cn(
            'transition-transform duration-300 ease-in-out transform min-h-[450px]',
            {
              'scale-100': isFullPreviewOpen,
              'scale-95': !isFullPreviewOpen
            }
          )}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </>
  )
}

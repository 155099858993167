import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '@/components/common/ui/breadcrumb.jsx'

function BreadcrumbWrapper({ pages, currentPage }) {
  return (
    <Breadcrumb>
      <BreadcrumbList className={'!gap-2'}>
        <BreadcrumbItem>
          {pages.map((page, index) => (
            <div key={index} className={'flex items-center gap-2'}>
              <BreadcrumbLink href={page.url}>{page.name}</BreadcrumbLink>
              <BreadcrumbSeparator />
            </div>
          ))}
        </BreadcrumbItem>
        <BreadcrumbItem className={'text-foreground'}>
          <Breadcrumb currentPage href={currentPage.url}>
            {currentPage.name}
          </Breadcrumb>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default BreadcrumbWrapper

import mediaSearchStore from '@/pages/MediaSearch/mediaSearchStore.js'

export const getMediaSearchPayload = () => {
  const mediaSearchStoreState = mediaSearchStore.getState()
  const mediaSearchData = mediaSearchStoreState.mediaSearchData
  const searchValue = mediaSearchStoreState.searchValue
  const lastKey = mediaSearchData.pageKeys[mediaSearchData.currentPage]
  return {
    search: searchValue,
    ...(lastKey && { lastKey })
  }
}

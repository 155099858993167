import { Separator } from '@/components/common/ui/separator'
import TimelineWarn from '@/assets/TimelineWarn.png'
import { formatDate } from '@/lib/utils'
import TimelineStop from '@/assets/icons/TimelineStop'
import TimelineCheck from '@/assets/icons/TimelineCheck'
import EscalatedIcon from '@/assets/icons/EscalatedIcon'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore'
import Loader from '@/components/common/ui/loader'

const successfulItem = ({ date, status }) => {
  return (
    <div className='relative flex pb-7'>
      <div className='absolute top-5 left-[7px] w-[1px] bg-gray-200 h-[200%] translate-x-2/4 '></div>
      <div className='my-[3px] z-40'>
        {status === 'completed' ? (
          <div className='bg-background z-50 h-5'>
            <TimelineCheck />
          </div>
        ) : (
          <div className='border border-background w-5 h-5 rounded-full bg-background '>
            <div className='bg-gray-300 rounded-full w-2 h-2 ml-1 mt-[5px]'></div>
          </div>
        )}
      </div>

      <div className=' px-4'>
        <div>
          <p className='text-sm font-medium'>Takedown successful</p>
        </div>
        <div className='pb-1'>
          <p className='text-[14px] font-normal text-foreground'>
            The content has been removed from the offending location.
          </p>
        </div>

        <div>
          <p className='text-xs font-normal text-muted-foreground'>
            {formatDate(date?.success)}
          </p>
        </div>
      </div>
    </div>
  )
}
const processingItem = ({ date, status, selectedTab }) => {
  return (
    <div className='relative flex pb-7'>
      <div className='absolute top-1 left-[7px] w-[1px] bg-gray-200 h-full translate-x-2/4 '></div>
      <div className='my-[3px] z-40 '>
        {status === 'processed' ? (
          <div className='bg-background z-50 h-5 overflow-hidden'>
            <TimelineStop />
          </div>
        ) : (
          <div className='border border-background w-5 h-5 rounded-full bg-background '>
            <div className='bg-gray-300 rounded-full w-2 h-2 ml-1 mt-[5px]'></div>
          </div>
        )}
      </div>
      <div className='px-4 flex justify-center flex-col'>
        <div>
          <p className='text-sm font-medium'>
            Takedown {status === 'escalated' ? 'notice(s)' : 'notice'} issued
          </p>
        </div>
        <div className='pb-1'>
          <p className='text-sm font-normal text-foreground'>
            {status === 'escalated'
              ? 'Several takedowns were issued and your content remains online.'
              : 'A takedown notice was issued on your behalf to the offending site.'}
          </p>
        </div>
        {status === 'processed' && (
          <div>
            <div className='py-1 bg-[#FEF2F2] px-2 rounded-md mb-3 inline-block'>
              <p className='text-sm text-red-700 font-normal text-foreground flex gap-2 items-center'>
                <Loader color='text-red-700' />
                We&apos;re monitoring the offending site for updates
                {selectedTab === 'accounts' && '.'}
                {selectedTab === 'media' &&
                  ", if the content is still visible in 48 hours, we'll escalate this to the next stage."}
              </p>
            </div>
          </div>
        )}
        <div className=''>
          <p className='text-xs font-normal text-muted-foreground'>
            {formatDate(date?.takedown)}
          </p>
        </div>
      </div>
    </div>
  )
}
const matchFoundItem = ({ date, status }) => {
  return (
    <div className={'relative flex pb-7'}>
      <div className='my-[3px] overflow-hidden bg-background'>
        {status === 'created' ? (
          <div className='bg-background z-50 h-5'>
            <img src={TimelineWarn} alt='img' />
          </div>
        ) : (
          <div className='border border-background w-5 h-5 rounded-full bg-background '>
            <div className='bg-gray-300 rounded-full w-2 h-2 ml-1 mt-[5px]'></div>
          </div>
        )}
      </div>
      <div className='px-4'>
        <div>
          <p className='text-sm font-medium'>Infringing content found</p>
        </div>
        <div className='pb-1'>
          <p className='text-sm font-normal text-foreground'>
            Content matching your likeness was found on a malicious site by our
            scrapers.
          </p>
        </div>

        <div>
          <p className='text-xs font-normal text-muted-foreground'>
            {formatDate(date?.created)}
          </p>
        </div>
      </div>
    </div>
  )
}
const escaltedItem = ({ date, status }) => {
  return (
    <div className='relative flex pb-7'>
      <div className='absolute top-5 left-[7px] w-[1px] bg-gray-200 h-[200%] translate-x-2/4 '></div>
      <div className='my-[3px] overflow-hidden bg-background'>
        {status === 'escalated' ? (
          <div className='bg-background z-50 h-5 overflow-hidden'>
            <EscalatedIcon />
          </div>
        ) : (
          <div className='border border-background w-5 h-5 rounded-full bg-background '>
            <div className='bg-gray-300 rounded-full w-2 h-2 ml-1 mt-[5px]'></div>
          </div>
        )}
      </div>
      <div className='px-4'>
        <div>
          <p className='text-sm font-medium'>
            Takedown escalated to legal team
          </p>
        </div>
        <div className='pb-1'>
          <p className='text-sm font-normal text-foreground'>
            Your content remains online despite Loti&apos;s efforts to remove
            it. This takedown has been referred to a legal expert.
          </p>
        </div>

        <div>
          <p className='text-xs font-normal text-muted-foreground'>
            {formatDate(date?.created)}
          </p>
        </div>
      </div>
    </div>
  )
}
export default function Timeline({ status, date }) {
  const { selectedTab } = useStore(takedownStore)
  const renderTimelineItems = () => {
    switch (status) {
      case 'created':
        return matchFoundItem({ date, status })
      case 'processed':
        return (
          <>
            {processingItem({ date, status, selectedTab })}
            {matchFoundItem({ date, status })}
          </>
        )
      case 'completed':
        return (
          <>
            {successfulItem({ date, status })}
            {processingItem({ date, status, selectedTab })}
            {matchFoundItem({ date, status })}
          </>
        )
      case 'escalated':
        return (
          <>
            {escaltedItem({ date, status })}
            {processingItem({ date, status, selectedTab })}
            {matchFoundItem({ date, status })}
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className=''>
        <div className='font-medium text-[16px] mb-2 mt-6'>
          <p>Updates</p>
        </div>
        <Separator />
        <div className='pt-5 overflow-hidden'>{renderTimelineItems()}</div>
      </div>
    </>
  )
}

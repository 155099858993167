import { Badge } from '@/components/common/ui/badge'
import { Button } from '@/components/common/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/common/ui/popover'
import { Separator } from '@/components/common/ui/separator'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper'
import { cn } from '@/lib/utils'
import { fetchSearches } from '@/pages/Watchtower/utils'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import {
  AArrowDown,
  AArrowUp,
  ArrowUpNarrowWide,
  ChevronsUpDown,
  X
} from 'lucide-react'
import { useDispatch } from 'react-redux'
import { useStore } from 'zustand'

export default function SortingCommandBox({ type = null, title = null }) {
  const dispatch = useDispatch()
  const {
    selectedTab,
    sortConfig,
    setSortConfig,
    setWatchtowerData,
    watchtowerData
  } = useStore(watchtowerStore)

  const fetchData = () => {
    //* Resetting the watchtowerData[selectedTab].currentPage and pageKeys to fetch 1st page on filter change.
    setWatchtowerData(selectedTab, {
      ...watchtowerData[selectedTab],
      currentPage: 1,
      lastKey: null,
      pageKeys: {},
      isLastPage: false,
      data: null
    })
    fetchSearches({
      dispatch,
      tab: selectedTab,
      page: 1
    })
  }

  const handleChange = (key, direction) => {
    let currentSortConfig = [...sortConfig]

    if (
      currentSortConfig.filter((item) => item.key === key)?.at(0)?.direction ===
      direction
    )
      return

    if (currentSortConfig.some((item) => item.key === key)) {
      currentSortConfig = currentSortConfig.map((item) =>
        item.key === key ? { ...item, direction } : item
      )
    } else {
      currentSortConfig = [...currentSortConfig, { key, direction }]
    }

    setSortConfig(currentSortConfig)

    if (key === 'followers_sorting') {
      fetchData(currentSortConfig)
    }
  }
  const handleSortClear = (key) => {
    let currentSortConfig = [...sortConfig]

    if (!currentSortConfig.some((item) => item.key === key)) return
    currentSortConfig = currentSortConfig.filter((item) => item.key !== key)
    setSortConfig(currentSortConfig)
    if (key === 'followers_sorting') {
      fetchData(currentSortConfig)
    }
  }

  const handleSortResetAll = () => {
    if (sortConfig.some((item) => item.key === 'followers_sorting')) {
      fetchData()
    }
    setSortConfig([])
  }

  const renderSorts = (key) => {
    return (
      <div className='grid grid-cols-[1fr_1fr_34px] rounded-md py-1 h-8 gap-1'>
        <div
          className={cn(
            'border border-input flex items-center justify-center rounded-md hover:bg-accent h-auto shadow-sm cursor-pointer gap-1',
            {
              'bg-accent':
                sortConfig.filter((item) => item.key === key)?.at(0)
                  ?.direction === -1
            }
          )}
          onClick={() => handleChange(key, -1)}
        >
          <span className='text-sm'>Asc</span>
          <AArrowUp
            className='w-5 h-5 text-muted-foreground'
            strokeWidth={1.5}
          />
        </div>
        <div
          className={cn(
            'border border-input flex items-center justify-center rounded-md hover:bg-accent h-auto shadow-sm cursor-pointer gap-1',
            {
              'bg-accent':
                sortConfig.filter((item) => item.key === key)?.at(0)
                  ?.direction === 1
            }
          )}
          onClick={() => handleChange(key, 1)}
        >
          <span className='text-sm'>Dsc</span>
          <AArrowDown
            className='w-5 h-5 text-muted-foreground'
            strokeWidth={1.5}
          />
        </div>
        <div
          className={cn(
            'border border-input flex items-center justify-center rounded-md hover:bg-accent h-auto shadow-sm cursor-pointer gap-1',
            {
              'bg-accent': !sortConfig.some((item) => item.key === key)
            }
          )}
          onClick={() => handleSortClear(key)}
        >
          <X className='h-4 w-4 text-muted-foreground' strokeWidth={1.5} />
        </div>
      </div>
    )
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        {type ? (
          <div className='flex items-center h-full'>
            {title}
            {(() => {
              const currentFilter = sortConfig
                .filter((item) => item.key === type)
                ?.at(0)

              if (currentFilter?.direction === 1) {
                return <AArrowDown className='w-4 h-4 text-green-500 ml-1' />
              }

              if (currentFilter?.direction === -1) {
                return <AArrowUp className='w-4 h-4 text-green-500 ml-1' />
              }

              return <ChevronsUpDown className='w-4 h-4' />
            })()}
          </div>
        ) : (
          <Button variant='outline' className='h-8 border-dashed'>
            <ArrowUpNarrowWide className='w-4 h-4' />
            <p className='font-medium'>Sort by</p>
            <div className='flex lg:hidden'>
              <Separator orientation='vertical' className='mx-2 h-4' />
              <BadgeWrapper
                variant='secondary'
                className='rounded-sm px-1 font-normal'
                text={
                  selectedTab !== 'impersonations'
                    ? sortConfig.filter((i) => i.key !== 'followers_sorting')
                        ?.length
                    : sortConfig?.length
                }
              />
            </div>

            <div className='hidden lg:flex items-center'>
              {sortConfig?.map((item) => {
                const title =
                  item.key === 'followers_sorting'
                    ? 'Followers'
                    : 'Threat Level'

                if (
                  selectedTab !== 'impersonations' &&
                  item.key === 'followers_sorting'
                )
                  return

                return (
                  <>
                    <Separator orientation='vertical' className='mx-2 h-4' />
                    <div
                      key={item.key}
                      className='hidden space-x-1 lg:flex font-normal text-sm'
                    >
                      {title}:
                      <Badge
                        variant='secondary'
                        className='rounded-sm px-1 font-normal ml-2'
                      >
                        {item.direction === 1 ? 'Desc' : 'Asc'}
                      </Badge>
                    </div>
                  </>
                )
              })}
            </div>
          </Button>
        )}
      </PopoverTrigger>

      <PopoverContent className='w-[200px] p-0 overflow-hidden' align='start'>
        <div className='p-2'>
          <p className='text-muted-foreground text-sm pb-2'>Sort Results By</p>
          <Separator />

          {!type || type === 'threat_level' ? (
            <div className='py-2'>
              <p className='text-sm'>Threat Level:</p>
              {renderSorts('threat_level')}
            </div>
          ) : null}
          {(!type || type === 'followers_sorting') &&
          selectedTab === 'impersonations' ? (
            <>
              <Separator />
              <div className='py-2'>
                <p className='text-sm'>Followers:</p>
                {renderSorts('followers_sorting')}
              </div>
            </>
          ) : null}
        </div>

        {sortConfig.length && !type ? (
          <>
            <Separator />
            <div
              className='p-2 text-center cursor-pointer hover:bg-accent'
              onClick={handleSortResetAll}
            >
              <p className='text-sm'>Reset {type ? title : 'All'}</p>
            </div>
          </>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}

import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import StatsCard from './StatsCard'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import AnalyticsIcon from '@/assets/icons/AnalyticsIcon'
import { Timer } from 'lucide-react'

export default function StatsNumbers({
  loading,
  globalLoading,
  setSelectedTab
}) {
  const navigate = useNavigate()
  const { searchCounts, searchSummary } = useStore(watchtowerStore)
  const handleWatchtowerNavigate = (tab) => {
    setSelectedTab(tab)
    navigate(`/watchtower?selectedTab=${tab}&reviewRequired=false`)
  }
  const handleTakedownsNavigate = (tab) => {
    navigate(`/takedown?selectedTab=${tab}`)
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4'>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={Number(searchSummary?.discovered?.total || 0).toLocaleString(
              'en-US'
            )}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All results'}
            displayType={'numbers'}
            data={searchCounts}
            name={'all'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: searchSummary?.takedown?.total || 0,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: searchSummary?.watchtower?.total || 0,
                onClick: () => handleWatchtowerNavigate('all')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={Number(
              searchSummary?.discovered?.deepfakes || 0
            ).toLocaleString('en-US')}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All deepfakes'}
            displayType={'numbers'}
            data={searchCounts}
            name={'deepfake'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: searchSummary?.takedown?.deepfakes || 0,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: searchSummary?.watchtower?.deepfakes || 0,
                onClick: () => handleWatchtowerNavigate('deepfake')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={Number(
              searchSummary?.discovered?.impersonators || 0
            ).toLocaleString('en-US')}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All impersonations'}
            displayType={'numbers'}
            data={searchCounts}
            name={'impersonators'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: searchSummary?.takedown?.total_accounts || 0,
                onClick: () => handleTakedownsNavigate('accounts')
              },
              {
                label: 'In watchtower',
                value: searchSummary?.watchtower?.impersonators || 0,
                onClick: () => handleWatchtowerNavigate('impersonations')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={Number(searchSummary?.discovered?.adult || 0).toLocaleString(
              'en-US'
            )}
            label={'All adult content'}
            icon={<AnalyticsIcon className='w-4' />}
            displayType={'numbers'}
            data={searchCounts}
            name='adult'
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: searchSummary?.takedown?.adult || 0,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: searchSummary?.watchtower?.adult || 0,
                onClick: () => handleWatchtowerNavigate('adult')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={`${searchSummary?.takedown?.average_time.toFixed(2) || 0} hrs`}
            label={'Avg. takedown time'}
            icon={<Timer className='w-4' />}
            displayType={'numbers'}
            data={searchCounts}
            name='adult'
            dataBreakdown={[
              {
                label: 'Requests',
                value: searchSummary?.takedown?.total || 0,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'Completed',
                value: searchSummary?.takedown?.total_completed || 0,
                onClick: () => handleTakedownsNavigate('media')
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}

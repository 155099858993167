import Enforce from '@/pages/Enforce/pages/Home/Enforce'
import EnforceActivity from '@/pages/Enforce/pages/EnforceActivity/EnforceActivity'
import Home from '@/pages/Home/Home'
import Profile from '@/pages/Profile/Profile'
import Takedowns from '@/pages/Takedowns/pages/Home/Takedowns'
import TakedownActivity from '@/pages/Takedowns/pages/TakedownActivity/TakedownActivity'
import Vault from '@/pages/Vault/pages/Home/Vault'
import VaultActivity from '@/pages/Vault/pages/VaultActivity/VaultActivity'
import Watchtower from '@/pages/Watchtower/pages/Home/Watchtower'
import InfoPage from '@/components/InfoPage/InfoPage'
import MediaSearch from '@/pages/MediaSearch/pages/Home/components/MediaSearch.jsx'

const routes = [
  //*********************** Home Routes ***********************//
  {
    path: '/',
    component: Home
  },
  {
    path: '/home/:id',
    component: Home
  },
  //*********************** Takedown Routes ***********************//
  {
    path: '/takedown',
    component: Takedowns
  },
  {
    path: '/takedown/:id',
    component: TakedownActivity
  },
  //*********************** Profile Routes ***********************//
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/profile/:id',
    component: Profile
  },
  //*********************** Watchtower Routes ***********************//
  {
    path: '/watchtower',
    component: Watchtower
  },
  {
    path: '/watchtower/:id',
    component: InfoPage
  },
  //*********************** Enforce Routes ***********************//
  {
    path: '/enforce',
    component: Enforce
  },
  {
    path: '/enforce/:id',
    component: EnforceActivity
  },
  //*********************** Vault Routes ***********************//
  {
    path: '/vault',
    component: Vault
  },
  {
    path: '/vault/:id',
    component: VaultActivity
  },
  //*********************** Media Search ***********************//
  {
    path: '/search-media',
    component: MediaSearch
  },
  {
    path: '/search-media/:keyword',
    component: MediaSearch
  }
]

export default routes

import { Separator } from '@/components/common/ui/separator'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import takedownStore from '@/pages/Takedowns/takedownStore'
import parse from 'html-react-parser'
import { useStore } from 'zustand'
import { cn, formatDate, getInitials, stringToColor } from '@/lib/utils.js'
import globalStore from '@/zustand/globalStore.js'

export default function NoticeIssued({ mails, email_address, type, sender }) {
  const { mailsLoading, selectedTab } = useStore(takedownStore)
  const { userMetaData } = useStore(globalStore)
  const titleMap = {
    domain: 'Takedown Issued',
    registrar: 'Domain registrar takedown notice issued',
    hosting: 'Host takedown notice issued',
    legal_team: 'Legal team escalation triggered'
  }
  const descMap = {
    domain:
      selectedTab === 'media'
        ? 'A takedown notice was sent to the site administrator on your behalf.'
        : ' A takedown notice was sent to the administrator of the account on your behalf.',
    registrar:
      "We've escalated the takedown to the domain registrar after the initial takedown notice was unsuccessful. A notice was sent on your behalf",
    hosting:
      "We've escalated the takedown to the website host after the initial takedown notice was unsuccessful. A notice was sent on your behalf.",
    legal_team:
      'The legal team has been escalated to assist with this takedown as the offending site is not responding to our requests.'
  }
  const sortedMails = Array.isArray(mails)
    ? mails.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : []
  const userInitials = getInitials(userMetaData?.full_name)
  const userAvatarBg = stringToColor(userMetaData?.full_name)

  const domainInitials = (name) => {
    return getInitials(name)
  }
  const domainAvatarBg = (name) => {
    return stringToColor(name)
  }
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='px-4'>
        <div className='font-medium text-[16px]'>
          <p>{titleMap[type]}</p>
        </div>
        <div className='text-sm py-1 font-normal text-muted-foreground'>
          <p>{descMap[type]}</p>
        </div>
        <div className='text-sm py-2 font-medium'>
          <p>Details</p>
        </div>
        <Separator />
        <div className='text-sm pt-4 px-2 rich-text-content overflow-hidden'>
          {mailsLoading ? (
            <SkeletonWrapper height={'20rem'} width={'100%'} number={1} />
          ) : selectedTab === 'media' ||
            (selectedTab === 'accounts' && mails.length > 0) ? (
            sortedMails ? (
              <div
                className={
                  'flex gap-4 flex-col text-wrap w-full overflow-hidden'
                }
              >
                {sortedMails?.map((mail, index) => (
                  <>
                    <div
                      key={index}
                      className={cn('p-4 rounded-md border overflow-hidden', {
                        'bg-green-50/50 ml-12 border border-green-500':
                          mail.email_type === 'outgoing',
                        'bg-gray-100 mr-12': mail.email_type === 'incoming'
                      })}
                    >
                      <div className={'flex items-center gap-2 mb-4'}>
                        <div
                          className={
                            'w-10 h-10 rounded-full text-white font-medium flex items-center justify-center text-lg'
                          }
                          style={{
                            backgroundColor: `${mail.email_type === 'outgoing' ? userAvatarBg : domainAvatarBg(sender)}`
                          }}
                        >
                          {mail.email_type === 'outgoing'
                            ? userInitials
                            : domainInitials(sender)}
                        </div>
                        <div className={'flex flex-col justify-center gap-1'}>
                          <div className='font-medium text-green-600 w-[95%] truncate'>
                            {mailsLoading ? (
                              <SkeletonWrapper
                                height={'1.5rem'}
                                width={'100%'}
                                number={1}
                              />
                            ) : selectedTab === 'media' ? (
                              (mail?.email_type === 'outgoing' &&
                                email_address) ||
                              (mail?.email_type === 'incoming' && sender) ||
                              'Unavailable'
                            ) : (
                              'goloti.com'
                            )}
                          </div>
                          <div className='text-xs font-medium text-muted-foreground'>
                            {formatDate(mail?.createdAt)}
                          </div>
                        </div>
                      </div>
                      <Separator className={'border'} />
                      <p className={'text-wrap mt-4'}>{parse(mail?.body)}</p>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              'Unavailable'
            )
          ) : (
            'We will continue to monitor the account for any future infringements.'
          )}
        </div>
      </div>
    </div>
  )
}

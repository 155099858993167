import { Badge } from '@/components/common/ui/badge.jsx'

const BadgeWrapper = ({ variant, text, className }) => {
  return (
    <Badge variant={variant} className={className}>
      {text}
    </Badge>
  )
}
export default BadgeWrapper

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../../ui/tooltip'

export default function TooltipWrapper({
  component,
  text,
  delayDuration,
  tooltipClassName = '',
  side = 'top'
}) {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger>{component}</TooltipTrigger>
        <TooltipContent side={side} className={tooltipClassName}>
          <p className='text-xs font-normal'>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

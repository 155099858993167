import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/common/ui/tabs.jsx'
import TabCard from '../../../../../../../../components/TabCard/TabCard.jsx'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import WatchtowerTable from '../../components/WatchtowerTable.jsx'
import Filters from '../../components/Filters.jsx'
import WatchtowerGrid from '../../components/WatchtowerGrid.jsx'

export default function ReviewedTabs({ onTabChange }) {
  const {
    watchtowerData,
    selectedTab,
    searchSummary,
    loading,
    tableLoading,
    activeLayout
  } = useStore(watchtowerStore)

  const tabs = [
    {
      label: 'All',
      value: 'all',
      contentValue: searchSummary?.watchtower?.total?.toLocaleString('en-us')
    },
    {
      label: 'Impersonations',
      value: 'impersonations',
      contentValue:
        searchSummary?.watchtower?.impersonators?.toLocaleString('en-us')
    },
    {
      label: 'Deepfakes',
      value: 'deepfake',
      contentValue:
        searchSummary?.watchtower?.deepfakes?.toLocaleString('en-us')
    },
    {
      label: 'Adult',
      value: 'adult',
      contentValue:
        searchSummary?.watchtower?.adult?.toLocaleString('en-us')
    },
    {
      label: 'Social',
      value: 'social',
      contentValue:
        searchSummary?.watchtower?.social?.toLocaleString('en-us')
    }
  ]
  return (
    <div className='w-full'>
      <Tabs
        defaultValue={selectedTab}
        className='w-full h-full'
        value={selectedTab}
        onValueChange={(value) => {
          onTabChange(value)
        }}
      >
        {loading && !searchSummary ? (
          <div className='w-full bg-transparent'>
            <SkeletonWrapper
              height={'6rem'}
              width={'100%'}
              number={5}
              className={'flex gap-4 w-full h-full sm:flex-row flex-col'}
            />
          </div>
        ) : (
          <TabsList className='h-fit bg-transparent grid sm:grid-cols-5 grid-cols-4 gap-4 '>
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className='data-[state=active]:ring-2 data-[state=active]:border-transparent data-[state=active]:ring-green-600 data-[state=active]:text-green-600 p-0 rounded-lg text-foreground border sm:col-span-1 col-span-2'
              >
                <TabCard
                  type={tab.label}
                  content={
                    <p className='text-lg font-semibold'>{tab.contentValue}</p>
                  }
                />
              </TabsTrigger>
            ))}
          </TabsList>
        )}
        <div>
          <Filters />
        </div>
        {tabs.map((tab) => (
          <TabsContent
            key={tab.value}
            value={tab.value}
            className='min-h-[calc(100vh-375px)]'
          >
            {(() => {
              if (loading || tableLoading) {
                if (activeLayout === 'table') {
                  return (
                    <SkeletonWrapper
                      number={8}
                      width={'100%'}
                      height={'4rem'}
                    />
                  )
                }

                if (activeLayout === 'grid') {
                  return (
                    <SkeletonWrapper
                      number={12}
                      height={'14rem'}
                      width={'10rem'}
                      className='flex flex-wrap sm:gap-6 gap-0 justify-between py-4'
                    />
                  )
                }
              }

              if (activeLayout === 'grid') {
                return (
                  <WatchtowerGrid
                    data={watchtowerData[selectedTab]?.data}
                    selectedTab={selectedTab}
                  />
                )
              }

              return (
                <WatchtowerTable
                  data={watchtowerData[selectedTab]?.data}
                  selectedTab={selectedTab}
                />
              )
            })()}
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}

import { all, put, takeLatest } from 'redux-saga/effects'
import API from '@/axios/Axios.js'
import * as actionTypes from '@/redux/MediaSearch/actionTypes.js'
import { toast } from 'sonner'

function* getSearches({ payload }) {
  try {
    const { data } = yield API.get('/asset/content-classification', {
      params: payload
    })
    if (data.success) {
      yield put({
        type: actionTypes.GET_SEARCH_MEDIA_SUCCESS,
        payload: data?.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SEARCH_MEDIA_FAILURE,
      payload: error?.response?.data
    })
    toast.error(error?.response?.data?.message || 'Failed to get searches')
  }
}

function* mediaSearchSaga() {
  yield all([takeLatest(actionTypes.GET_SEARCH_MEDIA, getSearches)])
}

export default mediaSearchSaga

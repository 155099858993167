import { useEffect } from 'react'

export const useCheckResultType = ({
  setDisableTakedownButton,
  selectedItems,
  data
}) => {
  useEffect(() => {
    const checkAdultResults = () => {
      const isNonAdult = selectedItems.some((id) => {
        const result = data?.find((item) => item?.search_results_id === id)
        return result && result?.category !== 'adult'
      })
      setDisableTakedownButton(isNonAdult)
    }

    checkAdultResults()
  }, [selectedItems, data, setDisableTakedownButton])
}

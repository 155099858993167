import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'
import API from '@/axios/Axios'
import {
  getReviewedPayload,
  getReviewRequiredPayload
} from '@/pages/Watchtower/utils.js'
import { getSearches as getSearchesAction } from '@/redux/Watchtower/actions.js'
import { toast } from 'sonner'
function* getSearches({ payload }) {
  const {
    lastKey,
    collection,
    match,
    limit = 24,
    is_deepfake,
    category,
    key,
    threat_level,
    followers_sorting,
    platform,
    search
  } = payload
  try {
    const config = {
      params: {
        lastKey,
        limit,
        match,
        ...(key === 'deepfake' && { is_deepfake }),
        ...(key === 'impersonations' && {
          category,
          ...(platform && { platform })
        }),
        ...(key !== 'all' &&
          key !== 'deepfake' &&
          key !== 'impersonations' &&
          key !== 'review_required' && { collection }),
        ...(key === 'impersonations'
          ? { overall_score_limit: threat_level }
          : { threat_level }),
        ...(key === 'impersonations' &&
          followers_sorting && { followers_sorting }),
        ...(search && { search })
      }
    }
    const response = yield API.get(`/asset/searches`, config)
    const { data } = response

    if (data.success) {
      yield put({
        type: actionTypes.GET_SEARCHES_SUCCESS,
        payload: {
          results: data.data.results,
          key,
          lastKey: data.data.lastKey
        }
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SEARCHES_FAILURE,
      payload: error?.message
    })
  }
}

function* getSingleSearch({ payload }) {
  try {
    const { data } = yield API.get(`/asset/searches/get?${payload}`)
    if (data.success) {
      yield put({
        type: actionTypes.GET_SINGLE_SEARCH_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_SEARCH_FAILURE,
      payload: error?.message
    })
  }
}

function* getSearchSummary({ payload }) {
  try {
    const { type, count } = payload
    const config = {
      params: {
        type,
        count
      }
    }
    const { data } = yield API.get(`/asset/search-summary`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_SEARCH_SUMMARY_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SEARCH_SUMMARY_FAILURE,
      payload: error?.message
    })
  }
}

function* getSearchSummaryChartData({ payload }) {
  try {
    const { type, count } = payload
    const config = {
      params: {
        type,
        count
      }
    }
    const { data } = yield API.get(`/asset/search-summary`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_SEARCH_SUMMARY_CHART_DATA_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SEARCH_SUMMARY_CHART_DATA_FAILURE,
      payload: error?.message
    })
  }
}

function* getDashboardDeepfakes({ payload }) {
  try {
    const { is_deepfake, limit, match = true } = payload
    const config = {
      params: {
        is_deepfake,
        limit,
        match
      }
    }
    const { data } = yield API.get(`/asset/searches`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_DEEPFAKES_SUCCESS,
        payload: data?.data?.results
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_DEEPFAKES_FAILURE,
      payload: error?.message
    })
  }
}

function* getDashboardVideos({ payload }) {
  try {
    const { collection, limit, type, match = true } = payload
    const config = {
      params: {
        collection,
        limit,
        type,
        match
      }
    }

    const { data } = yield API.get(`/asset/searches`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_VIDEOS_SUCCESS,
        payload: data?.data?.results
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_VIDEOS_FAILURE,
      payload: error?.message
    })
  }
}

function* getDashboardImpersonations({ payload }) {
  try {
    const { category, limit } = payload
    const config = {
      params: {
        category,
        limit,
        match: true
      }
    }

    const response = yield API.get(`/asset/searches`, config)
    const { data } = response
    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_IMPERSONATIONS_SUCCESS,
        payload: data?.data?.results
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_IMPERSONATIONS_FAILURE,
      payload: error?.message
    })
  }
}
function* getDashboardSocial({ payload }) {
  try {
    const { collection, limit, match = true } = payload
    const config = {
      params: {
        collection,
        limit,
        match
      }
    }

    const { data } = yield API.get(`/asset/searches`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_SOCIAL_SUCCESS,
        payload: data?.data?.results
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_SOCIAL_FAILURE,
      payload: error?.message
    })
  }
}
function* getSingleImpersonatorData({ payload }) {
  try {
    const { takedown_id } = payload
    const config = {
      params: {
        takedown_id
      }
    }

    const { data } = yield API.get(`/takedown/impersonator`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_SINGLE_IMPERSONATOR_DATA_SUCCESS,
        payload: data?.data?.results
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_IMPERSONATOR_DATA_FAILURE,
      payload: error?.message
    })
  }
}

function* setIsMeOrNotMe({ payload }) {
  try {
    const { ids, isMe, type } = payload
    const config = !type
      ? { search_results_ids: ids }
      : type === 'impersonations' && {
          username_platforms: ids,
          match: isMe ? 1 : -1
        }

    const endpoint = !type
      ? isMe
        ? `/asset/update-search-results`
        : `/asset/not-me`
      : type === 'impersonations' && `/asset/impersonators/review`

    const { data } =
      type === 'impersonations'
        ? yield API.put(endpoint, config)
        : yield API.post(endpoint, config)

    if (data.success) {
      yield put({
        type: actionTypes.SET_IS_ME_SUCCESS,
        payload: data?.data?.results
      })

      const payload = getReviewRequiredPayload()
      yield put(getSearchesAction({ payload }))
    }
  } catch (error) {
    console.log('ERROR', error)
    yield put({
      type: actionTypes.SET_IS_ME_FAILURE,
      payload: error?.message
    })
  }
}

function* setIsNotADeepfake({ payload }) {
  try {
    const { ids } = payload
    const config = {
      search_results_ids: ids,
      type: 'deepfakes'
    }

    const { data } = yield API.post(`/asset/update-search-results`, config)

    if (data.success) {
      yield put({
        type: actionTypes.SET_IS_ME_SUCCESS,
        payload: data?.data?.results
      })
      toast('Removed from deepfake', {
        description: 'The selected content has been marked as not a deepfake.'
      })

      const payload = getReviewedPayload()
      yield put(getSearchesAction({ payload }))
    }
  } catch (error) {
    console.log('ERROR', error)
    yield put({
      type: actionTypes.SET_IS_ME_FAILURE,
      payload: error?.message
    })
  }
}

function* watchtowerSaga() {
  yield all([
    takeLatest(actionTypes.GET_SEARCHES, getSearches),
    takeLatest(actionTypes.GET_SEARCH_SUMMARY, getSearchSummary),
    takeLatest(
      actionTypes.GET_SEARCH_SUMMARY_CHART_DATA,
      getSearchSummaryChartData
    ),
    takeLatest(actionTypes.GET_DASHBOARD_DEEPFAKES, getDashboardDeepfakes),
    takeLatest(actionTypes.GET_DASHBOARD_VIDEOS, getDashboardVideos),
    takeLatest(
      actionTypes.GET_DASHBOARD_IMPERSONATIONS,
      getDashboardImpersonations
    ),
    takeLatest(
      actionTypes.GET_SINGLE_IMPERSONATOR_DATA,
      getSingleImpersonatorData
    ),
    takeLatest(actionTypes.GET_DASHBOARD_SOCIAL, getDashboardSocial),
    takeLatest(actionTypes.GET_SINGLE_SEARCH, getSingleSearch),
    takeLatest(actionTypes.SET_IS_ME, setIsMeOrNotMe),
    takeLatest(actionTypes.SET_NOT_ME, setIsMeOrNotMe),
    takeLatest(actionTypes.SET_IS_NOT_DEEPFAKE, setIsNotADeepfake)
  ])
}

export default watchtowerSaga

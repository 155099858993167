import takedownStore from '@/pages/Takedowns/takedownStore.js'
import { fetchTakedowns } from '@/redux/Takedowns/actions.js'
import { useDispatch } from 'react-redux'
import { useStore } from 'zustand'
import TakedownTabs from './components/TakedownTabs'
import { useSearchParams } from 'react-router-dom'
import Pagination from '@/components/Pagination/Pagination.jsx'
import { useSetTabFromQuery } from '@/pages/Takedowns/hooks/useSetTabFromQuery.js'
import { useFetchData } from '@/pages/Takedowns/hooks/useFetchData.js'
import { DateTime } from 'luxon'
import { useFetchTakedownSummary } from '@/pages/Home/hooks/useFetchTakedownSummary.js'
import { useFetchSearchSummary } from '@/pages/Home/hooks/useFetchSearchSummary.js'

export default function Takedowns() {
  const dispatch = useDispatch()
  const {
    setSelectedTab,
    takedownData,
    selectedTab,
    setTakedownData,
    loading,
    dateValue,
    statusValue,
    platformValue
  } = useStore(takedownStore)
  const [searchParams, setSearchParams] = useSearchParams()

  const fetchData = (page) => {
    const tabData = takedownData[selectedTab]

    const payload = {
      lastKey: tabData.pageKeys[page] || null,
      category: selectedTab,
      ...(dateValue &&
        dateValue[selectedTab] && {
          start_date:
            DateTime.fromJSDate(dateValue[selectedTab]?.from).toFormat(
              'dd-MM-yyyy'
            ) || null,
          end_date:
            DateTime.fromJSDate(dateValue[selectedTab]?.to).toFormat(
              'dd-MM-yyyy'
            ) || null
        }),
      ...(statusValue[selectedTab]?.length && {
        status: statusValue[selectedTab].join(',')
      }),
      ...(platformValue[selectedTab]?.length && {
        platform: platformValue[selectedTab].join(',')
      })
    }
    dispatch(fetchTakedowns({ payload }))
  }

  useSetTabFromQuery({ searchParams, setSelectedTab })

  useFetchData({ takedownData, selectedTab, fetchData })

  useFetchSearchSummary()

  const handlePageChange = (page) => {
    setTakedownData(selectedTab, { currentPage: page })
    fetchData(page)
    window?.scrollTo(0, 0)
  }

  const handleTabChange = (tab) => {
    setSearchParams({ selectedTab: tab })
    setSelectedTab(tab)
  }
  return (
    <div className='pb-8 w-full max-w-screen-xl md:px-16 px-6'>
      <div className='flex justify-between'>
        <div className=''>
          <p className='text-xl font-semibold text-text-foreground'>
            Takedowns
          </p>
        </div>
      </div>
      <div className='my-2 w-full h-fit py-2'>
        <TakedownTabs onTabChange={handleTabChange} />
      </div>

      <div className='fixed bottom-0 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <Pagination
          currentPage={takedownData[selectedTab]?.currentPage}
          handlePageChange={handlePageChange}
          disableNext={
            takedownData[selectedTab]?.isLastPage ||
            loading ||
            !takedownData[selectedTab]?.data
          }
          disablePrev={takedownData[selectedTab]?.currentPage === 1 || loading}
          pageKeys={Object.keys(takedownData[selectedTab]?.pageKeys)}
          loading={takedownData[selectedTab]?.loading}
        />
      </div>
    </div>
  )
}

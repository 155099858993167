import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const APP_ID = 'fcww8o94'

// prettier-ignore
const loadIntercom = () => {(function () {
  var w = window
  var ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      var x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
})()
}

const initIntercom = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom('boot', { app_id: APP_ID, ...options })
}

export default function Intercom({ options, children }) {
  const location = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadIntercom()
      initIntercom(options)
    }
  }, [options])

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        window && window.Intercom && window.Intercom('update')
      }
    }

    handleRouteChange(location.pathname)
  }, [location])

  return children
}

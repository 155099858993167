import { formatDate } from '@/lib/utils'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'

export default function ActivityTimeline({
  title,
  last,
  onClick,
  id,
  selectedTab,
  date,
  badge
}) {
  return (
    <div className='w-full h-full relative pb-8'>
      {!last && (
        <div className='absolute top-2 left-[3px] w-[1px] bg-gray-200 h-[150%] translate-x-2/4 '></div>
      )}
      <div className='flex'>
        <div className={`my-2 z-40 pr-4 ${last ? 'bg-background' : ''}`}>
          <div className='bg-background z-50 '>
            <div className='h-6 -mt-2  flex justify-center items-center '>
              <div className='bg-gray-300 rounded-full w-2 h-2'></div>
            </div>
          </div>
        </div>
        <div onClick={onClick} className='w-full cursor-pointer'>
          <div
            className={`p-4 w-full rounded-md border ${id === selectedTab && 'border-green-600'}`}
          >
            <div className={'flex items-center justify-between'}>
              <p className='text-sm font-medium'>{title}</p>
              {badge && (
                <BadgeWrapper
                  text={badge}
                  className={`text-green-600 bg-gray-100 hover:bg-gray-100`}
                />
              )}
            </div>
            <div>
              <p className='text-xs font-normal text-muted-foreground'>
                {formatDate(date)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
